.App {
  text-align: center;
  height: calc(100vh - 120px);
  width: calc(100% - 120px);
    overflow: hidden;
}


.mobileContainer{
  display: none;
}


.mainContainer{
  height: 100vh;
  display: flex;
  flex-direction: row;
  padding: 60px;
  box-sizing: border-box;
  justify-content: space-between;
}

.leftContainer{
  background: #FFDBCA;
  border-radius:22px;
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

}

.rightContainer{
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 40px;
  box-sizing:border-box;

}

.yearLogoContainer{
  display: flex;
  margin-bottom: 35px;
}

.mock1{
  max-height: 90%;
  width: 90%;
  object-fit: contain;
}

.yearContainer{
  background: #FF621A;
  border-radius:100px;
  padding: 4px 15px;
  margin-right: 15px;
}

.currentYear {
  font-family: "Arimo";
  font-weight: 800;
  font-size:23px;
  color:white
}

.header{
  font-size: 55px;
  max-width: 490px;
  text-align: right;
  margin-bottom: 25px;
  line-height: 1;
}

.header2{
  font-weight:600;
  color: #7A7A7A;
  max-width: 330px;
  text-align: right;
  margin-bottom: 60px;
}

.header3{
  font-size: 28px;
  font-weight: 400;
  text-align: right;
  max-width: 410px;
  margin-bottom: 70px;
}

.buttonsContainer{
  background: #E4FFEC;
  border-radius:22px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  max-width: 670px;
  padding: 20px;
  padding-top: 40px;
  box-sizing: border-box;
}

.downloadText{
  font-weight: 600;
  font-size: 22px;
  max-width: 310px;
  text-align: center;
  margin-bottom: 40px;
}

.storeButton{
  height:70px;
  width: 235px;
  background-size: cover;
  border: none;
  margin: 0 7px;
}


.storeButton:hover {
  cursor: pointer;
}

.ratingContainer{
  display: flex;
  margin-bottom: 20px;
}

.rating{
  font-weight: 900;
  font-size: 25px;
  margin-right: 5px;
}


.centralContainer{
  position: absolute;
  height: 400px;
  width: 400px;
  background-size: contain;
  background-repeat: no-repeat;
  left: 36%;
  top: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.benefitContainer{
  background-color: white;
  border-radius:12px;
  padding: 15px;
  display: flex;
  width: auto;
  flex-direction: column;
  align-items:flex-end;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 70px;
  margin-right: 60px;
}

.benefitContainer2{
  margin-top: 20px;

}

.langs{
  font-weight: 700;
  margin-bottom: 13px;
  font-size: 20px;
}

.categories{
  font-weight: 700;
  font-size: 20px;
}

.categoriesHeader{
  font-size: 20px;
  margin-bottom: 5px;
}

.screensSection{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 100px;
}



.AppScreenBlockContainer{
  padding: 20px  80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}

.AppScreenBlockImg{
  width: 352px;
  height: 416px;
  margin-bottom: 30px;
}

.AppScreenBlockTitle{
  font-size: 23px;
  max-width: 260px;
  text-align: right;
  margin-bottom: 10px;
}

.AppScreenBlockText{
  font-size: 20px;
  max-width:280px;
  text-align: right;
}

.backLine{
  position: absolute;
  background: #FFDBCA;
  height: 215px;
  width: calc(100% - 120px);
  border-radius:20px;
  z-index: -2;
  margin-top:141px;
}

.scrensGridImg{
  position: absolute;
  z-index: -1;
  left: 55%;
  margin-top: 90px;
  height: 400px;
  width: 400px;
}

.footer2{
  display: flex;
  padding: 60px;
}

.FooterImg{
  width: 50%;
  height: 100%;
  border-radius:22px;
}
.footerBlock2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.footerTitle{
  font-size: 42px;
  text-align: center;
  max-width: 420px;
  margin-bottom: 55px;
}

.footerGrid{
  position: absolute;
  z-index: -1;
  height: 400px;

}

.supportLink{
  text-align: center;
  margin-bottom: 40px;
  color:#606060
}



@media (max-width: 1452px) {
.centralContainer{
  left: 31%;
}
}

@media (max-width: 1280px) {

  .desctopContainer{
    display: none;
  }

  .mobileContainer{
    display: block;
  }

  .buttonsContainer{
    max-width: 100%;
  }

  .mobileContainer{
    padding: 30px 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .yearLogoContainer-mobile{
    display: flex;
    justify-content: flex-end;
    align-items:center;
  }

  .currentYear-mobile{
    font-family: "Arimo";
    font-weight: 800;
    font-size:18px;
    color:white
  }

  .leftContainer-mobile{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    padding: 30px 0 0 0 ;
    margin-bottom: 30px;
  }
  .mock1{
     height: 400px;
  }

  .header, .header2, .header3{
    text-align: center;
  }

  .header{
    margin-bottom: 20px;
    font-size: 33px !important;
    max-width: 100px;
  }

  .header2{
    margin-bottom: 20px;
    font-size: 18px !important;
  }

  .centralContainer{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    width: 100%;
    margin-top: 30px;
  }

  .benefitContainer{
    margin: 0 0 20px 0 ;
  }

  .screensSection{
    margin-top: 0;
  }

  .AppScreenBlockContainer{
    padding: 0;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items:center;
  }

  .AppScreenBlockImg{
    margin-bottom: 10px; 
  }

  .AppScreenBlockTitle, .AppScreenBlockText{
    margin-right: 10px;
    text-align: center;
  }

  .backLine{
    height: 160%;
    width: 90%;
    background: #FFF2ED;
  }

  .footer2{
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin-bottom: 70px;
  }

  .FooterImg, .footerBlock2{
    width: 100%;
    margin-bottom: 40px;
  }

  .footerBlock2{
    margin-top: 30px;

  }

  .footerTitle{
    font-size: 33px;
    margin-bottom: 40px;
  }

  .footerButtons{
    display: flex;
    align-items:center;
    justify-content: center;
    flex-wrap: wrap;
  }

}









.Policy{
  padding-left: 60px;
}

h1, h2, h3, p {
  margin: 0;
}

.supportLink:hover{
  cursor: pointer;
}

.hover:hover{
  cursor: pointer;
}

@media (max-width: 1211px) {
  .App {
    overflow-y: auto;
    justify-content: flex-start !important;
    padding-left: 0 !important;
    width: 100%;
  }

  h1{
    font-size: 40px !important;
    min-width: 340px !important;
  }

  h2{
    font-size: 25px !important;
  }

  .storebtnsblock{
    margin-top: 40px !important;
  }
  .langblock{
    margin-top: 30px !important;
    padding: 20px !important;

  }

  .container1{
    margin-left: 30px !important;
  }

  .cnImages{
    margin-top: 15px;
  }

  .imagehand{
    width: 100% !important;
    margin-top: 80px;
  }

  .content {
    max-width: 90%;
    margin-left: 20px;
    margin-right: 20px;
    align-items: flex-start;
  }

  .image-container {
    width: 100%;
    min-width: unset;
    height: auto;
    align-self: center;
    margin-top: 20px;
    background-position: center;
  }

  .footer {
    position: absolute;
    bottom: 0;
  }

  .Policy{
    padding-left: 0px;
  }

}
